import React from 'react';
import line from '../assets/images/line.png';
import bankruptcy from '../assets/images/bankruptcy.png';
import gradient from '../assets/images/gradient.png';

export default function AboutUs() {
  return (
    <div className="container mx-auto px-6" id="aboutus">
      <div className="mt-16 lg:-mt-24 ">
        <p
          className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-aqua font-bold leading-[72px] text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          About us
        </p>
        <img src={line} alt="line" className="mt-6" />
      </div>
      <div className="mt-11 md:mt-32">
        <div className="md:flex justify-between gap-28">
          <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl md:w-1/2">
            <p data-aos="zoom-in" data-aos-delay="500" data-aos-duration="1000">
              "Bailout Refund" struggles to provie the financial assistance or stimulus packages to individuals, gainers/losers, or sectors during times of economic
              crisis.
            </p>
            <p className="mt-10" data-aos="zoom-in" data-aos-delay="700" data-aos-duration="1000">
              Let's restore meme trust with $BRFD and rebuild the future of a safe crypto sea. Participate in the bailout program buying $BRFD. You can then get recovered and get a refund of all your money previously lost.
            </p>
          </div>
          <div className="relative">
            <img src={bankruptcy} alt="bankruptcy" className="mt-8 md:mt-0 md:h-[500px]" />
            {/* <img src={gradient} alt="gradient" /> */}
            <div className="bg-[rgba(16,255,241,0.26)] blur-[55px] w-[185px] h-[200px] absolute -top-20 -right-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
