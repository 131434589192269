import React from 'react';
import AboutUs from '../components/AboutUs';
import HowToBuy from '../components/HowToBuy';
import Tokenomics from '../components/Tokenomics';
import Roadmap from '../components/Roadmap';

export default function Home() {
  return (
    <div id="home">
      <AboutUs />
      <HowToBuy />
      <Tokenomics />
      <Roadmap />
    </div>
  );
}
