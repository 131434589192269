import React from 'react';
import line from '../assets/images/line.png';
import wallet from '../assets/images/wallet.png';

const steps = [
  {
    id: 1,
    Title: 'Create a Wallet',
    subTitle:
      'Download meta mask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.',
  },
  {
    id: 2,
    Title: 'Get Some ETH',
    subTitle:
      'Have ETH in your wallet to switch to $BRFD. If you don’t have any ETH, you can buy directly on meta mask, transfer from another wallet, or buy on another exchange and send it to your wallet.',
  },
  {
    id: 3,
    Title: 'Go to Uniswap',
    subTitle:
      'Connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Met mask app. Connect your wallet. Paste the $BRFD token address into Uniswap, select Pepe, and confirm. When Meta mask prompts you for a wallet signature, sign.',
  },
  {
    id: 4,
    Title: 'Switch ETH for $BRFD',
    subTitle:
      'Switch ETH for $BRFD. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.',
  },
];

export default function HowToBuy() {
  return (
    <div className="container mx-auto px-6 relative backdrop:blur-lg" id="howtobuy">
      <div className="mt-16 sm:mt-24 md:mt-32 lg:mt-40 ">
        <p
          className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-aqua font-bold leading-[72px] text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          How to buy?
        </p>
        <img src={line} alt="line" className="mt-6" />
      </div>
      <div className="mt-20" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
        <div className="bg-buy-color border-[3px] border-white rounded-[46px] p-7 md:py-14 md:pl-14 md:pr-0 ">
          <div className="md:flex justify-between">
            <div className="md:w-2/3">
              <div>
                {steps.map((step, index) => {
                  return (
                    <>
                      <div className={`flex gap-5 ${step.id !== 1 && 'mt-16'} `}>
                        <div
                          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-aqua text-opacity-50 font-bold"
                          data-aos="fade-up"
                          data-aos-delay="300"
                          data-aos-duration="500"
                        >
                          0{step.id}
                        </div>
                        <div
                          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-opacity-50 font-bold"
                          data-aos="fade-up"
                          data-aos-delay="500"
                          data-aos-duration="500"
                        >
                          {step.Title}
                        </div>
                      </div>
                      <p
                        className="md:ml-[60px] mt-4 md:mt-8 text-xl sm:text-2xl text-[#d3d3d3] "
                        data-aos="fade-up"
                        data-aos-delay="700"
                        data-aos-duration="500"
                      >
                        {step.subTitle}
                      </p>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="md:w-1/2 flex items-center">
              <img
                src={wallet}
                alt="wallet"
                data-aos="fade-left"
                data-aos-delay="700"
                data-aos-duration="500"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[rgba(16,255,241,0.26)] blur-[150px] rounded-full w-[300px] h-[300px] absolute -left-48 -bottom-24"></div>
    </div>
  );
}
