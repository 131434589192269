import React from 'react';
import line from '../assets/images/line.png';
import round from '../assets/images/round.png';

const stpes = [
  {
    title: 'Phase 1',
    subtitle: 'Launch',
    phases: [
      {
        id: 1,
        phase1: '1,000+ Holders',
        phase2: 'Website relaunch',
        phase3: 'Social media accounts',
      },
    ],
  },
  {
    title: 'Phase 2',
    subtitle: 'Growth',
    phases: [
      {
        id: 2,
        phase1: '$BRFD merch',
        phase2: 'Some giveaways for OGs',
        phase3: '5,000+ Holders',
      },
    ],
  },
  {
    title: 'Phase 3',
    subtitle: 'Expansion',
    phases: [
      {
        id: 3,
        phase1: 'Initial CEX listing',
        phase2: '30,000+ Holders',
        phase3: 'Defi application',
      },
    ],
  },
  {
    title: 'Phase 4',
    subtitle: 'Utility',
    phases: [
      {
        id: 4,
        phase1: '50,000+ Holders',
        phase2: 'Bailout Refund Swap',
      },
    ],
  },
];

export default function Roadmap() {
  return (
    <div className="container mx-auto px-6 relative backdrop:blur-lg" id="roadmap">
      <div className="mt-16 sm:mt-24 md:mt-32 lg:mt-40 ">
        <p
          className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-aqua font-bold leading-[72px] text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          RoadMap
        </p>
        <img src={line} alt="line" className="mt-6" />
      </div>
      <div className="mt-20">
        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          {stpes.map((step, index) => {
            return (
              <div>
                <div
                  className="text-5xl font-bold text-aqua text-center"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="500"
                >
                  {step.title}
                </div>
                <div className="mt-2 text-center text-lg sm:text-xl md:text-2xl lg:text-3xl">
                  {step.subtitle}{' '}
                </div>
                <div className="mt-6">
                  <div
                    className="roadmap-bg border-2 rounded-[20px] p-6 lg:p-9 "
                    data-aos="flip-left"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    {step.phases.map((phase, index) => {
                      return (
                        <>
                          <div className="flex gap-3 items-center">
                            <img src={round} alt="round" className="flex items-center h-4" />
                            <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl">
                              {phase.phase1}{' '}
                            </div>
                          </div>
                          <div
                            className="flex gap-3 items-center mt-11 "
                            data-aos="fade-up"
                            data-aos-delay="900"
                            data-aos-duration="500"
                          >
                            <img src={round} alt="round" className="flex items-center h-4" />
                            <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl">
                              {phase.phase2}{' '}
                            </div>
                          </div>
                          <div
                            className="flex gap-3 items-center mt-11"
                            data-aos="fade-up"
                            data-aos-delay="1100"
                            data-aos-duration="500"
                          >
                            {phase.id !== 4 && (
                              <img src={round} alt="round" className="flex items-center h-4" />
                            )}
                            <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl">
                              {phase.phase3}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
