import React from 'react';
import line from '../assets/images/line.png';

const Tokens = [
  {
    id: 1,
    title: '$BRFD',
    subtitle: 'Token ticker',
  },
  {
    id: 2,
    title: '1 Billion ',
    subtitle: 'Token supply',
  },
  {
    id: 3,
    title: 'LP 100% Locked',
    subtitle: 'Security',
  },
  {
    id: 4,
    title: '0%',
    subtitle: 'Buy Tax',
  },
  {
    id: 5,
    title: '0%',
    subtitle: 'Sell Tax',
  },
  {
    id: 6,
    title: 'Yes',
    subtitle: 'Renounced',
  },
];

export default function Tokenomics() {
  return (
    <div className="container mx-auto px-6 relative backdrop:blur-lg" id="tokenomics">
      <div className="mt-16 sm:mt-24 md:mt-32 lg:mt-40 ">
        <p
          className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-aqua font-bold leading-[72px] text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Tokenomics
        </p>
        <img src={line} alt="line" className="mt-6" />
      </div>
      <div className="mt-20">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3  gap-12 ">
          {Tokens.map((token, index) => {
            return (
              <div>
                <div
                  className="font-bold text-2xl sm:text-4xl md:text-5xl lg:text-6xl text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="500"
                >
                  {token.title}
                </div>
                <div
                  className="mt-2 md:text-2xl text-aqua text-center"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="500"
                >
                  {token.subtitle}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
