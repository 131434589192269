import React from 'react';
import line from '../assets/images/line.png';
import twitter from '../assets/images/twitter.png';
import telegram from '../assets/images/telegram.png';

export default function Footer() {
  return (
    <div className="container mx-auto px-6 relative backdrop:blur-lg" id="aboutus">
      <div className="mt-16 sm:mt-24 md:mt-32 lg:mt-40 ">
        <p
          className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-aqua font-bold leading-[72px] text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Join us
        </p>
        <img src={line} alt="line" className="mt-6" />
      </div>
      <div className="mt-16">
        <p className="text-center text-[40px] leading-10 ">
          Bailout Refund is a decentralized group of 20+ volunteers community managers with
          expertise in <br /> development, moderation and group coordination.
        </p>
        <div className="flex justify-center mt-9 gap-6 mb-24">
          <a href="https://twitter.com/bailoutrefund" target="_blank">
            <img src={twitter} alt="twitter" className=" hover:scale-110 duration-300" />
          </a>
          <a href="https://t.me/bailoutrefund_official" target="_blank">
            <img src={telegram} alt="telegram" className=" hover:scale-110 duration-300" />
          </a>
        </div>
      </div>
    </div>
  );
}
