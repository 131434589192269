import React, { useState } from 'react';
import { ClipboardDocumentIcon, CheckIcon } from '@heroicons/react/24/outline';

export default function HeroSection() {
  const [copied, setCopied] = useState(false);

  return (
    <div className=" container mx-auto  px-6">
      <div className="flex mt-20 sm:mt-44 md:mt-52 lg:mt-60 md:pb-36">
        <div className="w-full  justify-center gap-4  animate__animated animate__backInLeft">
          <h1 className=" text-5xl sm:text-6xl md:text-7xl lg:text-7xl text-center  text-[#FFC700] text-shadow !font-La_Bamba_LET_Plain">
            Bailout Refund ($ BRFD)
          </h1>
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl  lg:leading-[65px] text-center">
            "Restore <span className="text-[#FFC700]">Trust</span>, Rebuild{' '}
            <span className="text-[#FFC700]">Safe Crypto Sea</span>" <br /> $BRFD is launched to
            assist losers in this meme world.
          </p>

          {/* <div className="mt-12 text-center">
            <p className="flex font-Caveat text-[32px] leading-[32px] gap-2 text-white justify-center">
              CA:
              <span className="text-title-yellow w-[100px] sm:w-[100px] md:w-full text-ellipsis overflow-hidden">
                0x7bdf3ff2513a4f467bc25b7fd4b8404ad8126cb3
              </span>
            </p>
          </div> */}
          {/* <div className="mt-12">
            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl  lg:leading-[65px] text-center">
              CA: 0x7bdf3ff2513a4f467bc25b7fd4b8404ad8126cb3
            </p>
          </div> */}
          <div className="flex gap-3 justify-center mt-12">
            <div className=" text-xl flex gap-2 sm:text-2xl md:text-3xl lg:text-[40px] md:leading-10 text-rebel text-ellipsis overflow-hidden animate__animated animate__bounceInRight animate__delay-1s">
              CA: <span>0x0000</span>{' '}
            </div>
            {!copied ? (
              <ClipboardDocumentIcon
                className="w-6 text-rebel cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(`0x0000`);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 500);
                }}
              />
            ) : (
              <CheckIcon className="w-6 text-rebel cursor-pointer" />
            )}
          </div>
          <div className="mt-24 md:flex justify-center items-center gap-12">
            <a href="https://t.me/bailoutrefund_official" target="_blank">
              <div className="cursor-pointer border_image font-poppins font-bold px-10 sm:px-20 text-lg sm:text-2xl md:text-3xl lg:text-3xl text-[#303958] bg-white rounded-full hover:scale-95 duration-300 active:scale-90 select-none text-center">
                Join community
              </div>
            </a>
            <a
              href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x000"
              target="_blank"
            >
              <div className="cursor-pointer mt-8 md:mt-0 border_image font-poppins font-bold px-10 sm:px-20 text-xl sm:text-2xl md:text-3xl lg:text-3xl text-[#303958] bg-white rounded-full hover:scale-95 duration-300 active:scale-90 select-none text-center">
                Buy Now
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
